import CryptoJS from 'crypto-js'
import { max } from 'lodash'
import jsPDF, { jsPDF as JsPDF } from 'jspdf'

import { Cidade, Estado } from '@store/globalApi/types'
import { Escolaridade, Patrimonio, Profissao } from '@store/proposta/escolaridade/types'
import { Pais } from '@store/proposta/nacionalidade/types'
import { Banco } from '@store/proposta/dadosBancarios/types'
import { FontesPagamento } from '@utils/enums'
import { BridgeService } from '@services/bridge'

export const filterSelectedPais = (arr: Pais[] | undefined, value: number | undefined) =>
  arr && arr.find((item) => item.value === Number(value))

export const filterSelectedPatrimonio = (arr: Patrimonio[] | undefined, key: number | undefined) =>
  arr && arr.find((item) => item.key === Number(key))

export const buildPaises = (paises: Pais[]) =>
  paises && paises?.map(({ value, title }) => ({ value, label: title }))

export const buildEstados = (estados: Estado[]) =>
  estados?.map(({ uf }) => ({ value: uf, label: uf }))

export const buildCidades = (cidades: Cidade[]) =>
  cidades?.map((cidade) => ({
    value: cidade.nome,
    label: cidade.nome,
  }))

export const buildEscolaridades = (escolaridades: Escolaridade[]) =>
  escolaridades?.map(({ descricao }) => ({
    value: descricao,
    label: descricao,
  }))

export const buildProfissoes = (profissoes: Profissao[]) =>
  profissoes?.map(({ nome }) => ({ value: nome, label: nome }))

export const buildPatrimonios = (patrimonios: Patrimonio[]) =>
  patrimonios && patrimonios?.map(({ key, descricao }) => ({ value: key, label: descricao }))

export const buildBancos = (bancos: Banco[]) =>
  bancos?.map(({ nome, codigo }) => ({ value: codigo, label: nome }))

export const filterSelectedBancos = (arr: Banco[] | undefined, codigo: string | undefined) =>
  arr && arr.find((item) => item.codigo === codigo)

export const orgaosBuild = (orgaos: string[]) =>
  orgaos?.map((orgao) => ({ value: orgao, label: orgao }))

export const TiposConta = [
  {
    value: '1',
    label: 'Conta Corrente',
  },
  {
    value: '2',
    label: 'Conta Poupança',
  },
]

export const findTipoConta = (tipoConta: string | number | undefined) =>
  TiposConta.find((item) => item.value === String(tipoConta) || item.label === tipoConta)

export const TipoCreditoApiInss = [
  {
    value: '1',
    label: 'Cartão Magnético',
  },
  {
    value: '2',
    label: 'Conta Corrente',
  },
]

export const findTipoCreditoApiInss = (tipoCredito: string | number | undefined) =>
  TipoCreditoApiInss.find(
    (item) =>
      item.value === String(tipoCredito) ||
      item.label.toUpperCase() === String(tipoCredito).toUpperCase(),
  )

export const GenerosTipos = [
  {
    value: 'Feminino',
    label: 'Feminino',
  },
  {
    value: 'Masculino',
    label: 'Masculino',
  },
]

export const fileToBase64 = (file: File) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = (error) => reject(error)
  })

export const base64toBlob = (base64: string, contentType = 'image/jpeg', sliceSize = 512) => {
  const byteCharacters = atob(base64.replace(/data:.+?,/, ''))
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i += 1) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }
  const blob = new Blob(byteArrays, { type: contentType })
  return blob
}

export const generateCheckSumByBlob = (blob: Blob, callback: (hash: string) => void) => {
  const reader = new FileReader()
  reader.readAsBinaryString(blob)
  reader.onloadend = () => {
    const hash = CryptoJS.MD5(String(reader.result)).toString()
    callback(hash)
  }
}

export const blobToFile = (blob: Blob, type: string, name: string) => {
  const date = new Date()
  const nomeArquivo = `${date.getTime()}_${name}`
  return new File([blob], nomeArquivo, {
    lastModified: date.getTime(),
    type,
  })
}

export const IsInssMagneticCard = (convenio: string, credito: string | undefined) => {
  const cartaoMagnetico = TipoCreditoApiInss[0].label
  if (convenio === FontesPagamento.INSS && credito?.toUpperCase() === cartaoMagnetico.toUpperCase())
    return true
  return false
}

export const validaEnvHomeByBridge = (isApp: boolean, isConsignado?: boolean) => {
  if (isApp) {
    return isConsignado
      ? `${process.env.REACT_APP_HOME_EMPRESTIMO}/consignado`
      : process.env.REACT_APP_HOME_EMPRESTIMO
  }
  return process.env.REACT_APP_HOME_SIMULADOR_NOVO
}

export function convertRotationToDegrees(rotation: number): number {
  let rotationInDegrees = 0
  switch (rotation) {
    case 8:
      rotationInDegrees = 270
      break
    case 6:
      rotationInDegrees = 90
      break
    case 3:
      rotationInDegrees = 180
      break
    default:
      rotationInDegrees = 0
  }
  return rotationInDegrees
}

export const getOrientation = (
  imageFile: File,
  onRotationFound: (rotationInDegrees: number) => void,
) => {
  const reader = new FileReader()
  reader.onload = (event: ProgressEvent) => {
    if (!event.target) {
      return
    }

    const innerFile = event.target as FileReader
    const view = new DataView(innerFile.result as ArrayBuffer)

    if (view.getUint16(0, false) !== 0xffd8) {
      onRotationFound(-2)
    }

    const length = view.byteLength
    let offset = 2

    while (offset < length) {
      if (view.getUint16(offset + 2, false) <= 8) {
        onRotationFound(-1)
      }
      const marker = view.getUint16(offset, false)
      offset += 2

      if (marker === 0xffe1) {
        offset += 2
        if (view.getUint32(offset, false) !== 0x45786966) {
          onRotationFound(-1)
        }

        const little = view.getUint16((offset += 6), false) === 0x4949
        offset += view.getUint32(offset + 4, little)
        const tags = view.getUint16(offset, little)
        offset += 2
        for (let i = 0; i < tags; i += 1) {
          if (view.getUint16(offset + i * 12, little) === 0x0112) {
            onRotationFound(view.getUint16(offset + i * 12 + 8, little))
          }
        }
      } else if ((marker & 0xff00) !== 0xff00) {
        break
      } else {
        offset += view.getUint16(offset, false)
      }
    }
    onRotationFound(-1)
  }
  reader.readAsArrayBuffer(imageFile)
}

export const rotateImageOrientation = async (
  imageSrc: string,
  orientation: number,
): Promise<string> => {
  const image = new Image()
  image.src = imageSrc

  const canvas = document.createElement('canvas')
  const ctx = canvas?.getContext('2d')

  return new Promise((resolve) => {
    image.addEventListener('load', () => {
      if (orientation > 4 && orientation < 9) {
        canvas.width = image.height
        canvas.height = image.width
      } else {
        canvas.width = image.width
        canvas.height = image.height
      }

      switch (orientation) {
        case 2:
          ctx?.transform(-1, 0, 0, 1, image.width, 0)
          break
        case 3:
          ctx?.transform(-1, 0, 0, -1, image.width, image.height)
          break
        case 4:
          ctx?.transform(1, 0, 0, -1, 0, image.height)
          break
        case 5:
          ctx?.transform(0, 1, 1, 0, 0, 0)
          break
        case 6:
          ctx?.transform(0, 1, -1, 0, image.height, 0)
          break
        case 7:
          ctx?.transform(0, -1, -1, 0, image.height, image.width)
          break
        case 8:
          ctx?.transform(0, -1, 1, 0, 0, image.width)
          break
        default:
          break
      }
      ctx?.drawImage(image, 0, 0)
      resolve(canvas?.toDataURL('image/jpeg'))
    })
  })
}

export const generatePDFLiveness = (files: string[]): string => {
  let jsPdf: jsPDF | null = null

  const anexosImagens = files

  const formato = 'JPEG'

  const largura = max(
    anexosImagens.map((a) => {
      const img = new Image()
      img.src = a
      img.onload = () => img.naturalWidth
      return img.naturalWidth
    }),
  )
  const altura = max(
    anexosImagens.map((a) => {
      const img = new Image()
      img.src = a
      img.onload = () => img.naturalHeight
      return img.naturalHeight
    }),
  )

  let pdfWidth = 0
  let pdfHeight = 0
  if (anexosImagens.length > 0) {
    anexosImagens.forEach((file) => {
      if (jsPdf) {
        jsPdf.addPage()
      } else {
        if (largura && altura) {
          jsPdf = new JsPDF({
            unit: 'px',
            orientation: largura > altura ? 'l' : 'p',
          })
        } else {
          jsPdf = new JsPDF({
            unit: 'px',
            orientation: 'p',
          })
        }
        pdfWidth = jsPdf.internal.pageSize.getWidth()
        pdfHeight = jsPdf.internal.pageSize.getHeight()
      }

      let imageWidth = 0
      let imageHeight = 0

      if (altura && largura) {
        if (altura * (pdfWidth / largura) > pdfHeight) {
          imageHeight = pdfHeight
          imageWidth = largura * (pdfHeight / altura)
        } else {
          imageWidth = pdfWidth
          imageHeight = altura * (pdfWidth / largura)
        }
      }

      jsPdf.addImage(file, formato, 0, 0, imageWidth, imageHeight)
    })
    // jsPdf.save('pdf_gerado.pdf') // Descomentar para testes, faz o download direto no navegador
    if (jsPdf) {
      const jsPdfFinal = (jsPdf as JsPDF).output('dataurlstring')
      return jsPdfFinal
    }
  }
  return ''
}
export const timestampToDatePt = (timestamp: number) => {
  if (!timestamp) return null
  const getDate =
    new Date(timestamp).getUTCDate() < 10
      ? `0${new Date(timestamp).getUTCDate()}`
      : new Date(timestamp).getUTCDate()
  const getMonth =
    new Date(timestamp).getMonth() + 1 < 10
      ? `0${new Date(timestamp).getMonth() + 1}`
      : new Date(timestamp).getMonth() + 1
  const getYear = new Date(timestamp).getFullYear()
  return `${getDate}/${getMonth}/${getYear}`
}

export const openLinkByEnviroment = async (url: string) => {
  if (BridgeService.isBrowser()) {
    window.location.href = url
  } else {
    await BridgeService.openDeepLink(url)
  }
}

export const nomeConvenioByCodigo: { [key: number]: string } = {
  1: 'ESTADO_MG',
  2: 'TJMG',
  4: 'ESTADO_RJ',
  10: 'ESTADO_SP',
  12: 'ESTADO_BAHIA',
  14: 'ESTADO_MS',
  20: 'ESTADO_GO',
  43: 'MIN_PUB_MG',
  45: 'TRE_MG',
  46: 'METRO',
  55: 'TRE_SP',
  56: 'STJ',
  62: 'TCE_MG',
  75: 'TRT',
  105: 'ESTADO_MT',
  13: 'MARINHA_BR',
  23: 'FORCA_AEREA_BR',
  37: 'EXERCITO_BR',
  5: 'PREF_BH',
  16: 'PREF_SALVADOR',
  17: 'PREF_SP',
  34: 'PREF_CONTAGEM',
  42: 'HOSPITAL_ODILON',
  6: 'INSS',
  3: 'SIAPE',
}
