import { BaseServices, HttpMethod } from '@services/base'

import { BridgeService } from './bridge'

const pathByProduto = {
  PORTABILIDADE: String(process.env.REACT_APP_BIOMETRICS_VALIDATE_PORTABILITY),
  REFINANCIAMENTO: String(process.env.REACT_APP_BIOMETRICS_VALIDATE),
}
export default async (biometricToken: string, produtoOrigem: string, accessToken?: string) => {
  const pathname =
    BridgeService.isBrowser() && accessToken
      ? `portal/bff/v1/${pathByProduto[produtoOrigem as 'PORTABILIDADE' | 'REFINANCIAMENTO']}`
      : pathByProduto.REFINANCIAMENTO
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_HOST
  const headers: Record<string, string> = {}
  if (BridgeService.isBrowser() && accessToken) {
    headers.Authorization = `Bearer ${accessToken}`
  }

  const response = await BaseServices.request({
    headers,
    pathname,
    method,
    host,
    data: { biometricToken },
  })
  return response
}
