import { BaseServices, HttpMethod } from '@services/base'

export default (data: TrackingRequest) => {
  const pathname = process.env.REACT_APP_NEW_RELIC_PATH_BFF
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_HOST
  const response = BaseServices.request({ pathname, method, host, data })
  return response
}

export interface TrackingRequest {
  numeroProposta?: string | number
  estadoNaturalidade?: string | number
  cidadeNaturalidade?: string | number
  step?: string
  estadoEndereco?: string
  cidadeEndereco?: string
  tipoConta?: number
  banco?: string | number
  valorEmprestimo?: number
  taxa?: number
  valorCET?: string
  valorLiberado?: number
  creditoProtegido?: boolean
  convenioId?: string | number
  antiFraude?: boolean
  clientId?: string | number
  session?: string | number
  eventType?: string
  origem: string
  familia: string
  produto: string
  documento: string
}
