import { BaseServices, HttpMethod } from '@services/base'

import { BridgeService } from './bridge'

export interface IResponseBiometriaDados {
  document: string
  name: string
  email: string
  cellPhone: string
  proposalCode: string
}

const pathByProduto = {
  PORTABILIDADE: String(process.env.REACT_APP_BIOMETRICS_DADOS_PORTABILITY),
  REFINANCIAMENTO: String(process.env.REACT_APP_BIOMETRICS_DADOS),
}

export default async (biometricToken: string, produtoOrigem: string, accessToken?: string) => {
  const pathname =
    BridgeService.isBrowser() && accessToken
      ? `portal/bff/v1/${pathByProduto[produtoOrigem as 'PORTABILIDADE' | 'REFINANCIAMENTO']}`
      : pathByProduto.REFINANCIAMENTO
  const method = HttpMethod.GET
  const host = process.env.REACT_APP_HOST
  const headers: Record<string, string> = {
    biometricToken,
  }
  if (BridgeService.isBrowser() && accessToken) {
    headers.Authorization = `Bearer ${accessToken}`
  }

  const response = await BaseServices.request<IResponseBiometriaDados>({
    headers,
    pathname,
    method,
    host,
  })
  return response
}
