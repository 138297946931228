/**
 * Actions Types
 */

export enum NavigationTypes {
  SET_IS_APP = '@navigation/SET_IS_APP',
  SET_ORIGEM = '@navigation/SET_ORIGEM',
  SET_TITLE = '@navigation/SET_TITLE',
  SET_HIDE_HEADER = '@navigation/SET_HIDE_HEADER',
  SET_HIDE_FOOTER = '@navigation/SET_HIDE_FOOTER',
  SET_IS_NEW_SIMULATOR_API = '@navigation/SET_IS_NEW_SIMULATOR_API',
  SET_FAMILIA = '@navigation/SET_FAMILIA',
  SET_NEW_VERSION = '@navigation/SET_NEW_VERSION',
  SET_PRODUTO_ORIGEM = '@navigation/SET_PRODUTO_ORIGEM',
  SET_ACCESS_TOKEN = '@navigation/SET_ACCESS_TOKEN',
  RESET = 'RESET',
}

/**
 * State Type
 */

export interface NavigationState {
  readonly isApp: boolean
  readonly origem: string
  readonly title: string
  readonly hideHeader: boolean
  readonly hideFooter: boolean
  readonly newSimulatorApi: boolean
  readonly familia: string
  readonly newVersion: boolean
  readonly produtoOrigem: string
  readonly accessToken?: string
}
