import { call, put, select } from 'typed-redux-saga'

import services from '@services'
import { showError } from '@store/ui/error/actions'
import { ApplicationState } from '@store/types'
import { HttpStatus } from '@services/base'

import { postValidateBiometrics, setLoadingBiometrics } from '../actions'

interface Props {
  payload: {
    callback: () => void
  }
  type: string
}

export type ErroType = {
  response: { status: HttpStatus; data: { message: string } }
  message: string
  status: HttpStatus
  data: {
    message: string
  }
}

export default function* postValidateBiometricsSaga({ payload }: Props) {
  const { biometricToken } = yield* select((s: ApplicationState) => s.captura.videoSelfie)
  const { accessToken, produtoOrigem } = yield* select((s: ApplicationState) => s.ui.navigation)
  yield put(setLoadingBiometrics(true))
  try {
    yield* call(services.postValidateBiometrics, biometricToken, produtoOrigem, accessToken)
    yield put(setLoadingBiometrics(false))
    yield payload.callback()
  } catch (error) {
    yield put(setLoadingBiometrics(false))
    yield put(
      showError({
        title: 'Ocorreu um erro ao validar biometria',
        message: 'Por favor tente novamente.',
        actionTitle: 'Tentar Novamente',
        actionCallback: () => postValidateBiometrics(payload),
        block: true,
      }),
    )
  }
}
