import { ResumoApi } from '@store/assinatura/resumo/types'
import { BaseServices, HttpMethod } from '@services/base'

export interface RequestDadosResumo {
  numeroProposta: string | number
  cpf: string
  email: string
  telefone: string
}

export default (data: RequestDadosResumo) => {
  const pathname = process.env.REACT_APP_DETALHES_COMPLETOS_PROPOSTA_BFF
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_HOST
  const response = BaseServices.request<ResumoApi>({
    pathname,
    method,
    host,
    data,
  })
  return response
}
